import { Box, Center, Text, Button } from '@chakra-ui/react';

import { DownloadIcon } from '@chakra-ui/icons';

import CV from '../assets/files/Juan_Zamora_CV.pdf';

const DownloadMyCv = () => {
  const handleCV = () => {
    var file_path = CV;
    var a = document.createElement('A');
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Box w="100%" bg="white" minH="15vh" p="4">
      <Center color="white" h="15vh" id="center">
        <Text color="green.600" fontSize={{ sm: '1', md: '3xl' }}>
          ¿Necesitas más información?
        </Text>
        <Button
          ml={{ sm: '1', md: '4' }}
          leftIcon={<DownloadIcon />}
          bg="green.600"
          color="white"
          variant="solid"
          onClick={() => handleCV()}
        >
          Descarga mi cv
        </Button>
      </Center>
    </Box>
  );
};

export default DownloadMyCv;
