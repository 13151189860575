import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Textarea,
  Button,
  Center,
} from '@chakra-ui/react';

import { EmailIcon } from '@chakra-ui/icons';

const Contact = () => {
  return (
    <Center w="100%" bg="green.600">
      <Box w="100%" minH="50vh" p="4" maxW="1200px">
        <Heading
          align="center"
          size="lg"
          w="100%"
          as="h2"
          color="white"
          isTruncated
        >
          Contacto
        </Heading>
        <Box color="white" w="100%" h="100%" p="4">
          <Box w="100%" h="100%" p={{ sm: '0', md: '4' }}>
            <Heading
              align="center"
              size="md"
              w="100%"
              as="h2"
              color="white"
              isTruncated
            >
              Envíame un email
            </Heading>
            <FormControl id="email" mt="2">
              <FormLabel>Dirección de email</FormLabel>
              <Input type="email" focusBorderColor="white" />
              <FormHelperText color="white">
                Nunca compatiré tu email.
              </FormHelperText>
            </FormControl>
            <FormControl id="name" mt="2">
              <FormLabel>Tu nombre</FormLabel>
              <Input type="text" focusBorderColor="white" />
            </FormControl>
            <FormControl id="message" mt="2">
              <FormLabel>Tu mensaje</FormLabel>
              <Textarea focusBorderColor="white" />
            </FormControl>
            <Button
              mt="2"
              leftIcon={<EmailIcon />}
              bg="white"
              color="green.600"
              w="100%"
              variant="solid"
            >
              {' '}
              Send Email{' '}
            </Button>
          </Box>
        </Box>
      </Box>
    </Center>
  );
};

export default Contact;
