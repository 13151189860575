import { Box, Heading, Text, Grid, Center, Image } from '@chakra-ui/react';

import brain from '../assets/images/brain.svg';
import book from '../assets/images/book.svg';

const Experience = () => {
  return (
    <Center w="100%">
      <Box w="100%" minH="50vh" p="4" maxW="1200px" marginTop="2em">
        <Heading
          align="center"
          size="lg"
          w="100%"
          as="h2"
          color="green.600"
          isTruncated
        >
          Presentación
        </Heading>
        <Box color="black" w="100%" h="100%" p="4" marginTop="1em">
          <Text p="2" align="center">
            Mi nombre es Juan Zamora Rey. Soy un apasionado de la lingüística,
            los idiomas y la programación. Desde pronto comencé a estudiar
            programación de forma paralela a mis estudios y aprendí de manera
            autodidacta html, css, python y SQL. Intentando combinar estos
            conocimientos con la enseñanza de idiomas llegué a la lingüística
            computacional, campo al que decidí consagrar mi vida profesional
            puesto que aunaba todo lo que amo.
          </Text>
          <Text p="2" align="center">
            Me considero una persona con mucha curiosidad intelectual y un
            inmenso deseo de aprender. Soy un trabajador empático, perseverante
            y eficaz. Normalmente consigo todo lo que me propongo y sé
            organizarme de forma óptima para completar mis metas. Me encanta el
            deporte, leer y la música.
          </Text>
        </Box>
        <Box color="black" w="100%" h="100%" p="4">
          <Grid
            templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
            gap={6}
          >
            <Box w="100%" h="100%" p="4">
              <Center>
                <Image
                  src={book}
                  alt="Linguista"
                  padding="0.5em"
                  maxWidth="30%"
                />
              </Center>
              <Heading
                align="center"
                size="md"
                w="100%"
                as="h2"
                color="green.600"
                isTruncated
              >
                Linguista
              </Heading>
              <Text mt="2" p="2" align="justify">
                He trabajado como profesor de español por muchos años por lo que
                conozco profundamente la lingüística y todo lo relacionado con
                sintaxis, morfología o pragmática (entre otros aspectos). Estos
                conocimientos me han servido, en el ámbito de la ligüística
                computacional, para determinar la intencionalidad de los
                usuarios con el chatbot o predecir errores y diseñar respuestas.
              </Text>
            </Box>
            <Box w="100%" h="100%" p="4">
              <Center>
                <Image src={brain} alt="NLP" padding="0.5em" maxWidth="30%" />
              </Center>
              <Heading
                align="center"
                size="md"
                w="100%"
                as="h2"
                color="green.600"
                isTruncated
              >
                Desarrollo e IT
              </Heading>
              <Text mt="2" p="2" align="justify">
                De manera autónoma he realizado cursos y pequeños proyectos
                personales para formarme en programación. El lenguaje del que
                poseo mayores conocimientos es Python. En lo referente a
                lenguajes de marcado y estilo tengo conocimientos abundantes en
                HTML, CSS y XML. Por su parte en lenguaje de BBDD tengo
                conocimientos de SQL y cypher para bases de datos de grafos. En
                mi último proyecto utilicé neo4j
              </Text>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Center>
  );
};

export default Experience;
