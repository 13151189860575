import { Box, Center, Avatar } from '@chakra-ui/react';

import heroImg from '../assets/images/hero.jpg';
import profile from '../assets/images/profile.png';

const Hero = () => {
  return (
    <Box
      w="100%"
      bgImage={'url(' + heroImg + ')'}
      backgroundSize="cover"
      h="50vh"
      p="2"
    >
      <Center color="white" h="100%">
        <Avatar
          src={profile}
          name="Juan Zamora"
          size="2xl"
          minWidth="10%"
          minHeight="40%"
        />
      </Center>
    </Box>
  );
};

export default Hero;
