import { Box, Image, Center, Grid, Text, Link } from '@chakra-ui/react';
import GithubImg from '../assets/images/github-brands.svg';
import linkedinImg from '../assets/images/linkedin-brands.svg';

const Footer = () => {
  const handleGoToUrl = (url) => {
    window.open(url);
  };

  return (
    <Box
      w="100%"
      bg="green.600"
      h={{ sm: '15vh', md: '14vh' }}
      color="white"
      p="2"
    >
      <Grid templateColumns="repeat(2, 1fr)" gap={6} mt="4">
        <Center w="100%">
          <Image
            src={GithubImg}
            maxH="5vh"
            maxW="5vw"
            cursor="pointer"
            onClick={() => handleGoToUrl('https://github.com/Juanzamorarey')}
          />
        </Center>
        <Center w="100%">
          <Image
            src={linkedinImg}
            maxH="5vh"
            maxW="5vw"
            cursor="pointer"
            onClick={() =>
              handleGoToUrl(
                'https://www.linkedin.com/in/juan-zamora-rey-250194138/'
              )
            }
          />
        </Center>
      </Grid>
      <Box mt="2">
        <Text size="sm" align="center">
          Developed by{' '}
          <Link href="https://www.mzrdeveloper.com" isExternal>
            Mzrdeveloper
          </Link>{' '}
          in 2021
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
