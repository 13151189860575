import { ChakraProvider } from '@chakra-ui/react';
import Hero from '../components/Hero';
import Experience from '../components/Experience';
import Contact from '../components/Contact';
import DownloadMyCv from '../components/DownloadMyCv';
import Footer from '../components/Footer';

const App = () => {
  return (
    <ChakraProvider>
      <Hero />
      <Experience />
      <Contact />
      <DownloadMyCv />
      <Footer />
    </ChakraProvider>
  );
};

export default App;
